<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ $t("hr.imprest_approval.imprest_approval") }}
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="data">
        <v-flex xs12 mt-2>
          <span
            v-html="
              $t('hr.imprest_approval.message', [
                `${data.imprest.employee.firstName} ${data.imprest.employee.lastName}`,
                $helpers.formattedDate(data.imprest.timestamp),
                $helpers.getConvertCurrency(data.imprest.requested)
              ])
            "
          />
        </v-flex>
        <v-flex xs12>
          <validation-provider
            :name="$t('currency.approved_amount')"
            rules="required|max:255|min_value:0|max_value:999999"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="imprestApprovalDTO.approvedAmount"
              v-numeric.number.decimal
              :label="$t('currency.approved_amount')"
              :error-messages="errors"
              :append-icon="$helpers.getCurrencyIcon(currencyCode)"
            />
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <validation-provider :name="$t('global.note')" rules="max:512" v-slot="{ errors }">
            <v-text-field
              v-model.trim="imprestApprovalDTO.note"
              name="note"
              :label="$t('global.note')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="imprestApprovalDTO.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      item: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      data: null,
      imprestApprovalDTO: {
        approved: false,
        note: null,
        approvedAmount: null
      },
      currencyCode: null
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      item: {
        handler(data) {
          if (data) {
            this.data = data;
            this.imprestApprovalDTO.approvedAmount = data.imprest.requested.amount;
            this.currencyCode = data.imprest.requested.code;
          }
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.imprestApprovalDTO.approved = null;
        this.imprestApprovalDTO.note = null;
        this.data = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.imprestService
          .approve(this.data.id, this.imprestApprovalDTO)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.imprestApprovalDTO.note = null;
              this.imprestApprovalDTO.note = null;
              this.data = null;
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
