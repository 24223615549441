var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("hr.imprest_approval.imprest_approval"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.onClickCancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.data)?_c('v-card-text',{staticClass:"pt-3"},[_c('v-flex',{attrs:{"xs12":"","mt-2":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('hr.imprest_approval.message', [
              ((_vm.data.imprest.employee.firstName) + " " + (_vm.data.imprest.employee.lastName)),
              _vm.$helpers.formattedDate(_vm.data.imprest.timestamp),
              _vm.$helpers.getConvertCurrency(_vm.data.imprest.requested)
            ])
          )}})]),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('currency.approved_amount'),"rules":"required|max:255|min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"numeric",rawName:"v-numeric.number.decimal",modifiers:{"number":true,"decimal":true}}],attrs:{"label":_vm.$t('currency.approved_amount'),"error-messages":errors,"append-icon":_vm.$helpers.getCurrencyIcon(_vm.currencyCode)},model:{value:(_vm.imprestApprovalDTO.approvedAmount),callback:function ($$v) {_vm.$set(_vm.imprestApprovalDTO, "approvedAmount", _vm._n($$v))},expression:"imprestApprovalDTO.approvedAmount"}})]}}],null,false,413802597)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.note'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"note","label":_vm.$t('global.note'),"error-messages":errors},model:{value:(_vm.imprestApprovalDTO.note),callback:function ($$v) {_vm.$set(_vm.imprestApprovalDTO, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"imprestApprovalDTO.note"}})]}}],null,false,1003569150)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.imprestApprovalDTO.approved),callback:function ($$v) {_vm.$set(_vm.imprestApprovalDTO, "approved", $$v)},expression:"imprestApprovalDTO.approved"}},[_c('v-radio',{attrs:{"color":"primary","value":false,"label":_vm.$t('leave.request.denied')}}),_c('v-radio',{attrs:{"color":"primary","value":true,"label":_vm.$t('leave.request.approved')}})],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"dialog-footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClickSave($event)}}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }